import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { VehicleSearch, Vehicledelete, VehicleAdd, VehicleEdit, VehicleGetAll,VehicleByCusomerid,VehicleByDriverid, VehicleUpdateimages, VehicleViewid,VehicleActive } from './VehicleApi';
import moment from 'moment';


interface Vehicle {
	fullname: string;
}

interface VehicleState {
	allVehicle: Vehicle[],
	filterVehicle: Vehicle[],
	currentVehicle:Vehicle[]
	searchVehicle:Vehicle[]
}
const initialState: VehicleState = {
	allVehicle: [],
	filterVehicle:[],
	currentVehicle:[],
	searchVehicle:[]
};

// local storege
const token: string | null = localStorage.getItem('token');
console.log(token);
//





//get fetchVehicleAll
const fetchVehicleAll = createAsyncThunk('Vehicle/fetchVehicleAll', async () => {
	const response = await VehicleGetAll(token as string);
	const data=response.map((item:any)=>{
         item.dob=moment( item.dob).format('YYYY-MM-DD')
		return item
	})
	console.log(data ,'==============>>>>>>>>>>all Vehicle');
	return data; // Assuming the response is an array of Vehicles
});





// add addVehicleAll

const addVehicleAll = createAsyncThunk('Vehicle/addVehicleAll', async (data: any) => {
	try {
		const response = await VehicleAdd(token as string, data );
		// return response;
	  console.log(response?.ok)
	  if(response?.ok as any){
         await VehicleGetAll(token as string)
		
	  }
	} catch (error) {
		 console.log(error)
	}
});

// editVehicle
const editVehicleAll = createAsyncThunk('/Vehicles/updateVehicle', async (data: any) => {
	console.log(data)
	const{modify,id}=data
	try {
		const response = await VehicleEdit(token as string, modify,id as any );
		
		if(response?.ok as any){
			await VehicleGetAll(token as string)
			return response;
		   
		 }
	} catch (error) {
		 console.log(error)
	}
});

const updateVehicleImages=createAsyncThunk('Vehicle/updateVehicleImages',async(data:any)=>{
	console.log(data)
	const{modifyImages,id}=data
	try {
		const response=await VehicleUpdateimages(modifyImages,id)
		return response
	} catch (error) {
		console.log(error)
	}
})


//****************search api******* */
const searchVehicle =createAsyncThunk('Vehicle/serachVehicle',async(name:any)=>{
	try {
		const response=await VehicleSearch(name)
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
	}
})


/*************************delete api****************** */
const deleteVehicle =createAsyncThunk(
	'Vehicle/deleteVehicle', async (id:any)=>{
		try {
			
			const response =await Vehicledelete(id as any)
			console.log(response)
			 
				await VehicleGetAll(token as string)
			 
			// return response
		} catch (error) {
			console.log(error)
		}
	}
)








// get by id

const VehicleView = createAsyncThunk('Vehicle/VehicleView', async (id:any) => {
	const response = await VehicleViewid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Vehicles
});


//Vehicle get by customer id

const VehiclebyCusomerid = createAsyncThunk('Vehicle/VehicleByCusomerid', async (id:any) => {
	const response = await VehicleByCusomerid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Vehicles
});
//Vehicle get by customer id

const VehicleBydriverid = createAsyncThunk('Vehicle/VehicleBydriverid', async (id:any) => {
	const response = await VehicleByDriverid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Vehicles
});



// get by id

const VehiclesActive = createAsyncThunk('Vehicle/VehicleActive', async (one:any  ) => {
	const response = await VehicleActive(one);
	console.log(response);
	return response; // Assuming the response is an array of Vehicles
});










const VehicleSlice = createSlice({
	name: 'VehicleSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetchVehicleAll.fulfilled, (state, action: any) => {
			// state.allVehicle .push(...action.payload);
			state.allVehicle=action.payload
		});
		builder.addCase(searchVehicle.fulfilled, (state, action: any) => {
			// state.allVehicle .push(...action.payload);
			state.allVehicle=action.payload
		});
		builder.addCase(VehicleView.fulfilled, (state, action: any) => {
			state.currentVehicle=action.payload

			// state.currentVehicle.push(...action.payload);
		});
		builder.addCase(VehicleBydriverid.fulfilled, (state, action: any) => {
			state.allVehicle=action.payload

			// state.currentVehicle.push(...action.payload);
		});
		builder.addCase(VehiclebyCusomerid.fulfilled, (state, action: any) => {
			state.allVehicle=action.payload

			// state.currentVehicle.push(...action.payload);
		});
		 
		builder.addCase(addVehicleAll.fulfilled, (state, action: any) => {
            state.allVehicle.filter((items:any)=>items._id!==action.payload)
		});
		builder.addCase(VehiclesActive.fulfilled, (state, action: any) => {
            state.filterVehicle= action.payload
		});
		 
	},
});

export default VehicleSlice.reducer;

export { fetchVehicleAll, addVehicleAll,editVehicleAll,updateVehicleImages,deleteVehicle,VehiclesActive,VehicleBydriverid  ,VehicleView,VehiclebyCusomerid,searchVehicle};
