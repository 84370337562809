import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RideSearch, Ridedelete, RideAdd, RideEdit, rideGetAll,RideByCusomerid,RideByDriverid, RideUpdateimages, RideViewid,RideFilter } from './RideApi';
import moment from 'moment';


interface Ride {
	fullname: string;
}

interface RideState {
	allRide: Ride[],
	filterRide: Ride[],
	currentRide:Ride[]
	searchRide:Ride[]
}
const initialState: RideState = {
	allRide: [],
	filterRide:[],
	currentRide:[],
	searchRide:[]
};

// local storege
const token: string | null = localStorage.getItem('token');
console.log(token);
//





//get fetchRideAll
const fetchRideAll = createAsyncThunk('Ride/fetchRideAll', async () => {
	const response = await rideGetAll(token as string);
	const data=response.map((item:any)=>{
         item.dob=moment( item.dob).format('YYYY-MM-DD')
		return item
	})
	console.log(data ,'==============>>>>>>>>>>all Ride');
	return data; // Assuming the response is an array of Rides
});





// add addRideAll

const addRideAll = createAsyncThunk('Ride/addRideAll', async (data: any) => {
	try {
		const response = await RideAdd(token as string, data );
		// return response;
	  console.log(response?.ok)
	  if(response?.ok as any){
         await rideGetAll(token as string)
		
	  }
	} catch (error) {
		 console.log(error)
	}
});

// editRide
const editRideAll = createAsyncThunk('/Rides/updateRide', async (data: any) => {
	console.log(data)
	const{modify,id}=data
	try {
		const response = await RideEdit(token as string, modify,id as any );
		
		if(response?.ok as any){
			await rideGetAll(token as string)
			return response;
		   
		 }
	} catch (error) {
		 console.log(error)
	}
});

const updateRideImages=createAsyncThunk('Ride/updateRideImages',async(data:any)=>{
	console.log(data)
	const{modifyImages,id}=data
	try {
		const response=await RideUpdateimages(modifyImages,id)
		return response
	} catch (error) {
		console.log(error)
	}
})


//****************search api******* */
const searchRide =createAsyncThunk('Ride/serachRide',async(name:any)=>{
	try {
		const response=await RideSearch(name)
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
	}
})


/*************************delete api****************** */
const deleteRide =createAsyncThunk(
	'Ride/deleteRide', async (id:any)=>{
		try {
			
			const response =await Ridedelete(id as any)
			console.log(response)
			 
				await rideGetAll(token as string)
			 
			// return response
		} catch (error) {
			console.log(error)
		}
	}
)








// get by id

const RideView = createAsyncThunk('Ride/RideView', async (id:any) => {
	const response = await RideViewid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Rides
});


//ride get by customer id

const RidebyCusomerid = createAsyncThunk('Ride/RideByCusomerid', async (id:any) => {
	const response = await RideByCusomerid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Rides
});
//ride get by customer id

const RideBydriverid = createAsyncThunk('Ride/RideBydriverid', async (id:any) => {
	const response = await RideByDriverid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Rides
});



// get by id

const RidesActive = createAsyncThunk('Ride/RideActive', async (one:any  ) => {
	const response = await RideFilter(one);
	console.log(response);
	return response; // Assuming the response is an array of Rides
});










const RideSlice = createSlice({
	name: 'RideSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetchRideAll.fulfilled, (state, action: any) => {
			// state.allRide .push(...action.payload);
			state.allRide=action.payload
		});
		builder.addCase(searchRide.fulfilled, (state, action: any) => {
			// state.allRide .push(...action.payload);
			state.allRide=action.payload
		});
		builder.addCase(RideView.fulfilled, (state, action: any) => {
			state.currentRide=action.payload

			// state.currentRide.push(...action.payload);
		});
		builder.addCase(RideBydriverid.fulfilled, (state, action: any) => {
			state.allRide=action.payload

			// state.currentRide.push(...action.payload);
		});
		builder.addCase(RidebyCusomerid.fulfilled, (state, action: any) => {
			state.allRide=action.payload

			// state.currentRide.push(...action.payload);
		});
		 
		builder.addCase(addRideAll.fulfilled, (state, action: any) => {
            state.allRide.filter((items:any)=>items._id!==action.payload)
		});
		builder.addCase(RidesActive.fulfilled, (state, action: any) => {
            state.filterRide= action.payload
		});
		 
	},
});

export default RideSlice.reducer;

export { fetchRideAll, addRideAll,editRideAll,updateRideImages,deleteRide,RidesActive,RideBydriverid  ,RideView,RidebyCusomerid,searchRide};
