const BASE_URL= 'https://nest.relie.one/';
 









//*********************          CustmoerGetAll start            ***************** */ 

export const TransactionGetAll = async (token: string) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			'x-access-token': token,
		},
	};
 
	try {
		const response = await fetch(`${BASE_URL}transactions/getall?page_no=1&page_size=11`,options,);
		const data = await response.json();
		console.log(response,'===>>>>>>VehicleBook');
		return data.reverse();
	} catch (error) {
		console.log('error', error);
	}
};
 














 




 
 




 