import { configureStore } from '@reduxjs/toolkit'
 import DriverSlice from './pages/presentation/Relie_Admin/DRIVERCRUD copy/DriverSlice/DriverSlice'
 import CustomerSlice from './pages/presentation/Relie_Admin/Customer/CustomerSlice/Slice'
 import RideSlice from './pages/presentation/Relie_Admin/RideBook/RideSilce/RideSlice'
 import FareSlice from './pages/presentation/Relie_Admin/ManageFare/FareSilce/FareSilce'
 import VehicleSlice from './pages/presentation/Relie_Admin/ManageVehicle/VehicleSlice/VehicleSlice'
 import TransactionSlice from './pages/presentation/Relie_Admin/Transactions/TransactionSlice/TransSlice'

const store=configureStore({
    reducer:{
        driver:DriverSlice,
        customer:CustomerSlice,
        ride:RideSlice,
        fare:FareSlice,
        vehicle:VehicleSlice,
        transaction:TransactionSlice,
    }
})
export default store
