import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CustomerSearch, Customerdelete, CustomerAdd, CustomerEdit, CustmoerGetAll, CustomerUpdateimages, CustomerViewid,CustomerActive } from './Api';
import moment from 'moment';


interface Customer {
	fullname: string;
}

interface CustomerState {
	allCustomer: Customer[],
	filterCustomer: Customer[],
	currentCustomer:Customer[]
	searchCustomer:Customer[]
}
const initialState: CustomerState = {
	allCustomer: [],
	filterCustomer:[],
	currentCustomer:[],
	searchCustomer:[]
};

// local storege
const token: string | null = localStorage.getItem('token');
console.log(token);
//





//get fetchCustomerAll
const fetchCustomerAll = createAsyncThunk('Customer/fetchCustomerAll', async () => {
	const response = await CustmoerGetAll(token as string);
	const data=response.map((item:any)=>{
         item.dob=moment( item.dob).format('YYYY-MM-DD')
		return item
	})
	console.log(data ,'==============>>>>>>>>>>all Customer');
	return data; // Assuming the response is an array of Customers
});





// add addCustomerAll

const addCustomerAll = createAsyncThunk('Customer/addCustomerAll', async (data: any) => {
	try {
		const response = await CustomerAdd(token as string, data );
		// return response;
	  console.log(response?.ok)
	  if(response?.ok as any){
         await CustmoerGetAll(token as string)
		
	  }
	} catch (error) {
		 console.log(error)
	}
});

// editCustomer
const editCustomerAll = createAsyncThunk('/Customers/updateCustomer', async (data: any) => {
	console.log(data)
	const{modify,id}=data
	try {
		const response = await CustomerEdit(token as string, modify,id as any );
		
		if(response?.ok as any){
			await CustmoerGetAll(token as string)
			return response;
		   
		 }
	} catch (error) {
		 console.log(error)
	}
});

const updateCustomerImages=createAsyncThunk('Customer/updateCustomerImages',async(data:any)=>{
	console.log(data)
	const{modifyImages,id}=data
	try {
		const response=await CustomerUpdateimages(modifyImages,id)
		return response
	} catch (error) {
		console.log(error)
	}
})


//****************search api******* */
const searchCustomer =createAsyncThunk('Customer/serachCustomer',async(name:any)=>{
	try {
		const response=await CustomerSearch(name)
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
	}
})


/*************************delete api****************** */
const deleteCustomer =createAsyncThunk(
	'Customer/deleteCustomer', async (id:any)=>{
		try {
			
			const response =await Customerdelete(id as any)
			console.log(response)
			 
				await CustmoerGetAll(token as string)
			 
			// return response
		} catch (error) {
			console.log(error)
		}
	}
)








// get by id

const CustomerView = createAsyncThunk('Customer/CustomerView', async (id:any) => {
	const response = await CustomerViewid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Customers
});



// get by id

const CustomersActive = createAsyncThunk('Customer/CustomerActive', async (one:any  ) => {
	const response = await CustomerActive(one);
	console.log(response);
	return response; // Assuming the response is an array of Customers
});










const CustomerSlice = createSlice({
	name: 'CustomerSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetchCustomerAll.fulfilled, (state, action: any) => {
			// state.allCustomer .push(...action.payload);
			state.allCustomer=action.payload
		});
		builder.addCase(searchCustomer.fulfilled, (state, action: any) => {
			// state.allCustomer .push(...action.payload);
			state.allCustomer=action.payload
		});
		builder.addCase(CustomerView.fulfilled, (state, action: any) => {
			state.currentCustomer=action.payload

			// state.currentCustomer.push(...action.payload);
		});
		builder.addCase(addCustomerAll.fulfilled, (state, action: any) => {
            state.allCustomer.filter((items:any)=>items._id!==action.payload)
		});
		builder.addCase(CustomersActive.fulfilled, (state, action: any) => {
            state.filterCustomer= action.payload
		});
	},
});

export default CustomerSlice.reducer;
export { fetchCustomerAll, addCustomerAll,editCustomerAll,updateCustomerImages,deleteCustomer,CustomersActive ,CustomerView,searchCustomer};
