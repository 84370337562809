import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FareSearch, Faredelete, FareAdd, FareEdit, FareGetAll,FareByCusomerid,FareByDriverid, FareUpdateimages, FareViewid,FareActive } from './FareApoi';
import moment from 'moment';


interface Fare {
	fullname: string;
}

interface FareState {
	allFare: Fare[],
	filterFare: Fare[],
	currentFare:Fare[]
	searchFare:Fare[]
}
const initialState: FareState = {
	allFare: [],
	filterFare:[],
	currentFare:[],
	searchFare:[]
};

// local storege
const token: string | null = localStorage.getItem('token');
console.log(token);
//





//get fetchFareAll
const fetchFareAll = createAsyncThunk('Fare/fetchFareAll', async () => {
	const response = await FareGetAll(token as string);
	const data=response.map((item:any)=>{
         item.dob=moment( item.dob).format('YYYY-MM-DD')
		return item
	})
	console.log(data ,'==============>>>>>>>>>>all Fare');
	return data; // Assuming the response is an array of Fares
});





// add addFareAll

const addFareAll = createAsyncThunk('Fare/addFareAll', async (data: any) => {
	try {
		const response = await FareAdd(token as string, data );
		// return response;
	  console.log(response?.ok)
	  if(response?.ok as any){
         await FareGetAll(token as string)
		
	  }
	} catch (error) {
		 console.log(error)
	}
});

// editFare
const editFareAll = createAsyncThunk('/Fares/updateFare', async (data: any) => {
	console.log(data)
	const{modify,id}=data
	try {
		const response = await FareEdit(token as string, modify,id as any );
		
		if(response?.ok as any){
			await FareGetAll(token as string)
			return response;
		   
		 }
	} catch (error) {
		 console.log(error)
	}
});

const updateFareImages=createAsyncThunk('Fare/updateFareImages',async(data:any)=>{
	console.log(data)
	const{modifyImages,id}=data
	try {
		const response=await FareUpdateimages(modifyImages,id)
		return response
	} catch (error) {
		console.log(error)
	}
})


//****************search api******* */
const searchFare =createAsyncThunk('Fare/serachFare',async(name:any)=>{
	try {
		const response=await FareSearch(name)
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
	}
})


/*************************delete api****************** */
const deleteFare =createAsyncThunk(
	'Fare/deleteFare', async (id:any)=>{
		try {
			
			const response =await Faredelete(id as any)
			console.log(response)
			 
				await FareGetAll(token as string)
			 
			// return response
		} catch (error) {
			console.log(error)
		}
	}
)








// get by id

const FareView = createAsyncThunk('Fare/FareView', async (id:any) => {
	const response = await FareViewid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Fares
});


//Fare get by customer id

const FarebyCusomerid = createAsyncThunk('Fare/FareByCusomerid', async (id:any) => {
	const response = await FareByCusomerid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Fares
});
//Fare get by customer id

const FareBydriverid = createAsyncThunk('Fare/FareBydriverid', async (id:any) => {
	const response = await FareByDriverid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of Fares
});



// get by id

const FaresActive = createAsyncThunk('Fare/FareActive', async (one:any  ) => {
	const response = await FareActive(one);
	console.log(response);
	return response; // Assuming the response is an array of Fares
});










const FareSlice = createSlice({
	name: 'FareSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetchFareAll.fulfilled, (state, action: any) => {
			// state.allFare .push(...action.payload);
			state.allFare=action.payload
		});
		builder.addCase(searchFare.fulfilled, (state, action: any) => {
			// state.allFare .push(...action.payload);
			state.allFare=action.payload
		});
		builder.addCase(FareView.fulfilled, (state, action: any) => {
			state.currentFare=action.payload

			// state.currentFare.push(...action.payload);
		});
		builder.addCase(FareBydriverid.fulfilled, (state, action: any) => {
			state.allFare=action.payload

			// state.currentFare.push(...action.payload);
		});
		builder.addCase(FarebyCusomerid.fulfilled, (state, action: any) => {
			state.allFare=action.payload

			// state.currentFare.push(...action.payload);
		});
		 
		builder.addCase(addFareAll.fulfilled, (state, action: any) => {
            state.allFare.filter((items:any)=>items._id!==action.payload)
		});
		builder.addCase(FaresActive.fulfilled, (state, action: any) => {
            state.filterFare= action.payload
		});
		 
	},
});

export default FareSlice.reducer;

export { fetchFareAll, addFareAll,editFareAll,updateFareImages,deleteFare,FaresActive,FareBydriverid  ,FareView,FarebyCusomerid,searchFare};
