import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {  TransactionGetAll } from './TransApi';
import moment from 'moment';


interface Transaction {
	fullname: string;
}

interface TransactionState {
	allTransaction: Transaction[],
	filterTransaction: Transaction[],
	currentTransaction:Transaction[]
	searchTransaction:Transaction[]
}
const initialState: TransactionState = {
	allTransaction: [],
	filterTransaction:[],
	currentTransaction:[],
	searchTransaction:[]
};

// local storege
const token: string | null = localStorage.getItem('token');
console.log(token);
//





//get fetchTransactionAll
const fetchTransactionAll = createAsyncThunk('Transaction/fetchTransactionAll', async () => {
	const response = await TransactionGetAll(token as string);
	const data=response.map((item:any)=>{
         item.dob=moment( item.dob).format('YYYY-MM-DD')
		return item
	})
	console.log(data ,'==============>>>>>>>>>>all Transaction');
	return data; // Assuming the response is an array of Transactions
});



 






const TransactionSlice = createSlice({
	name: 'TransactionSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetchTransactionAll.fulfilled, (state, action: any) => {
			// state.allTransaction .push(...action.payload);
			state.allTransaction=action.payload
            console.log(state.allTransaction,'==== allTrans in builder')
		});
		 
		 
	},
});

export default TransactionSlice.reducer;

export { fetchTransactionAll };
